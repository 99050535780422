@use 'stylesheets/setup' as *;
@use 'sass:color';

.redesign-announcement-modal {
  .ui-modal {
    overflow: hidden;
  }

  .redesign-announcement-swiper {
    margin-top: 6rem;

    @include slice-from(sm) {
      text-align: center;
    }

    .swiper-slide {
      width: 90%;
      max-width: 36rem;
      padding: 8rem 2rem 2rem;
      background: palette(offwhite);
      border-radius: vars(border-radius, large);

      &:nth-child(even) {
        background: color.scale(palette(offwhite), $lightness: -5%);
      }

      img {
        position: absolute;
        top: 0;
        left: 50%;
        width: auto;
        height: auto;
        transform: translate(-50%, -50%);
      }

      h2 {
        margin-top: 0;
        color: palette(red);
      }
    }
    .swiper-pagination {
      display:flex;
      justify-content: center;
      position:relative;
      width: 100%;
      margin-top: 2rem;

      .swiper-pagination-bullet-active {
        background: palette(red);
      }
    }
  }
}
